import React, { useEffect, useState } from "react";
import {
  CustomLoadingIndicator,
  CustomModal,
  CustomTooltipComponent,
  Header,
} from "../../components";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { MdAdd, MdDelete } from "react-icons/md";
import { themeColorsUsable } from "../../data/buildData";

const ProductionGroups = () => {
  const {
    currentColor,
    loggedIn,
    loadingIndicatorActive,
    setLoadingIndicatorActive,
  } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [domainsAsOptions, setDomainsAsOptions] = useState([]);
  const [groupDomainsAsOptions, setGroupDomainsAsOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [productionGroups, setProductionGroups] = useState([]);
  const [allProductionGroups, setAllProductionGroups] = useState([]);
  const [selectedProductionGroups, setSelectedProductionGroups] = useState([]);
  const [selectedDomainsIds, setSelectedDomainsIds] = useState([]);
  const [selectedGroupDomainsIds, setSelectedGroupDomainsIds] = useState([]);

  const [editingGroup, setEditingGroup] = useState(null);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [editingGroupName, setEditingGroupName] = useState("");

  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
  const [openAddDomainsToGroupModal, setOpenAddDomainsToGroupModal] =
    useState(false);

  const elementsCreateGroupModal = [
    {
      type: "title",
      props: {
        label: "Create new group",
      },
    },
    {
      type: "select",
      props: {
        label: "Choose domains",
        name: "Choose domains",
        value: selectedDomainsIds,
        onChange: (e) => {
          setSelectedDomainsIds(
            typeof e.target.value === "string"
              ? e.target.value.split(",")
              : e.target.value
          );
        },
        renderValue: (selected) => {
          const selectedDomains = domainsAsOptions
            .filter((item) => selected.includes(item.value))
            .map((item) => item.label);
          return selectedDomains.join(", ");
        },
        required: true,
        multiple: true,
      },
      options: domainsAsOptions,
    },
  ];

  const elementsAddDomainsToGroupModal = [
    {
      type: "title",
      props: {
        label: `Add domains to ${editingGroup?.name || "group"}`,
      },
    },
    {
      type: "select",
      props: {
        label: "Choose domains",
        name: "Choose domains",
        value: selectedGroupDomainsIds,
        onChange: (e) => {
          setSelectedGroupDomainsIds(
            typeof e.target.value === "string"
              ? e.target.value.split(",")
              : e.target.value
          );
        },
        renderValue: (selected) => {
          const selectedDomains = groupDomainsAsOptions
            .filter((item) => selected.includes(item.value))
            .map((item) => item.label);
          return selectedDomains.join(", ");
        },
        required: true,
        multiple: true,
      },
      options: groupDomainsAsOptions,
    },
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getAllDomains = async () => {
    setLoadingIndicatorActive(true);

    try {
      const response = await axiosPrivate.get(
        `/api/v1/production/domains/getAll`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.success) {
        const tempDomainsArr = [];
        response.data.data.forEach((domain) => {
          if (!domain?.productionGroup) {
            tempDomainsArr.push({
              label: domain.domainName,
              value: domain._id,
            });
          }
        });
        setDomainsAsOptions(tempDomainsArr);
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const getProductionGroups = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/groups/get`,
        {
          page,
          limit,
          sortField: "createdAt",
          sortOrder: "desc",
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.data?.success) {
        setProductionGroups(response?.data?.data);
        setTotalPages(Math.ceil(response?.data?.total / limit));
        if (!response?.data?.data.length) {
          setPage(1);
        }
        getAllDomains();
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const getProductionGroupsByIds = async (groups) => {
    const productionGroupsIds = groups.map((g) => g.id);
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/groups/getByIds`,
        {
          productionGroupsIds,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.data?.success) {
        setProductionGroups(response?.data?.data);
        setTotalPages(1);
        setPage(1);
        getAllDomains();
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const getAllProductionGroups = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/groups/get`,
        {
          sortField: "createdAt",
          sortOrder: "desc",
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.data?.success) {
        const transformedGroups = response?.data?.data.map((item) => ({
          id: item._id,
          label: item.name,
        }));
        setAllProductionGroups(transformedGroups);
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const handleCreateGroup = async () => {
    setLoadingIndicatorActive(true);
    try {
      if (selectedDomainsIds.length) {
        const response = await axiosPrivate.post(
          `/api/v1/production/groups/create`,
          {
            selectedDomainsIds,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data?.success) {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("success");
          setSelectedDomainsIds([]);
          setOpenCreateGroupModal(false);
          if (selectedProductionGroups.length) {
            getProductionGroupsByIds(selectedProductionGroups);
          } else {
            getProductionGroups();
          }
          getAllProductionGroups();
        } else {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("error");
        }
      } else {
        setSnackbarMessage("Please choose at least one domain.");
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const addDomainToGroup = async (groupId) => {
    const group = productionGroups.find((g) => g._id === groupId);
    setEditingGroup(group);
    const availableDomains = [];
    domainsAsOptions.map((domain) => {
      if (!group.domains.map((d) => d._id).includes(domain.value)) {
        availableDomains.push(domain);
      }
    });
    if (availableDomains.length) {
      setGroupDomainsAsOptions(availableDomains);
      setOpenAddDomainsToGroupModal(true);
    } else {
      setSnackbarMessage(`${group.name} already includes all domains.`);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  const deleteGroup = async (groupId) => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/groups/delete`,
        {
          groupId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data?.success) {
        setSnackbarMessage(response.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        if (selectedProductionGroups.length) {
          getProductionGroupsByIds(selectedProductionGroups);
        } else {
          getProductionGroups();
        }
        getAllProductionGroups();
      } else {
        setSnackbarMessage(response.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  const removeDomainFromGroup = async (groupId, domainId) => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        `/api/v1/production/groups/update`,
        {
          groupId,
          domains: [domainId],
          action: "remove",
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data?.success) {
        setSnackbarMessage(response.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        if (selectedProductionGroups.length) {
          getProductionGroupsByIds(selectedProductionGroups);
        } else {
          getProductionGroups();
        }
      } else {
        setSnackbarMessage(response.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
        setLoadingIndicatorActive(false);
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setLoadingIndicatorActive(false);
    }
  };

  const handleEditGroupName = async (group) => {
    setEditingGroupId(group._id);
    setEditingGroupName(group.name);
  };

  const handleKeyDown = async (e) => {
    if (e.code === "Enter") {
      try {
        setLoadingIndicatorActive(true);
        const response = await axiosPrivate.post(
          `/api/v1/production/groups/update`,
          {
            groupId: editingGroupId,
            name: editingGroupName,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data?.success) {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("success");
          setEditingGroupId(null);
          setEditingGroupName("");
          if (selectedProductionGroups.length) {
            getProductionGroupsByIds(selectedProductionGroups);
          } else {
            getProductionGroups();
          }
          getAllProductionGroups();
        } else {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("error");
        }
      } catch (error) {
        setSnackbarMessage(error?.response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      } finally {
        setLoadingIndicatorActive(false);
      }
    }
  };

  const handleAddDomainsToGroup = async () => {
    setLoadingIndicatorActive(true);
    try {
      if (selectedGroupDomainsIds.length) {
        const group = productionGroups.find((g) => g._id === editingGroup._id);
        const filteredDomainsIds = group.domains.map((d) => d._id);
        selectedGroupDomainsIds.forEach((domainId) => {
          filteredDomainsIds.push(domainId);
        });
        const response = await axiosPrivate.post(
          `/api/v1/production/groups/update`,
          {
            groupId: group._id,
            domains: selectedGroupDomainsIds,
            action: "add",
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data?.success) {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("success");
          setEditingGroup(null);
          setSelectedGroupDomainsIds([]);
          setOpenAddDomainsToGroupModal(false);
          if (selectedProductionGroups.length) {
            getProductionGroupsByIds(selectedProductionGroups);
          } else {
            getProductionGroups();
          }
        } else {
          setSnackbarMessage(response.data.message);
          setShowSnackbar(true);
          setSnackbarSeverity("error");
        }
      } else {
        setSnackbarMessage("Please choose at least one domain.");
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      if (selectedProductionGroups.length) {
        getProductionGroupsByIds(selectedProductionGroups);
      } else {
        getProductionGroups();
      }
    } else {
      navigate("/login");
    }
  }, [page, limit, loggedIn, selectedProductionGroups]);

  useEffect(() => {
    getAllProductionGroups();
  }, []);

  return (
    <div className="m-2 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Production" title="Groups" />
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <Box>
          <Autocomplete
            multiple
            id="groups-list"
            options={allProductionGroups}
            disableCloseOnSelect
            sx={{ width: 300 }}
            onChange={(event, values) => {
              setSelectedProductionGroups(values);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Choose groups"
                placeholder="Groups"
              />
            )}
          />
        </Box>
        <CustomTooltipComponent
          icon={MdAdd}
          tooltipText="Create New Group"
          onClick={() => setOpenCreateGroupModal(true)}
          currentColor={currentColor}
        />
      </Box>
      <Box sx={{ my: 5 }}>
        {productionGroups.length ? (
          productionGroups.map((group) => {
            return (
              <Box key={group._id} sx={{ marginBottom: "50px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  {editingGroupId && editingGroupId === group._id ? (
                    <TextField
                      value={editingGroupName}
                      autoFocus
                      onBlur={() => setEditingGroupId(null)}
                      onChange={(e) => setEditingGroupName(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      gutterBottom
                      onDoubleClick={() => handleEditGroupName(group)}
                      color={"black"}
                    >
                      {group.name}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <CustomTooltipComponent
                      icon={MdAdd}
                      tooltipText="Add a domain"
                      onClick={() => addDomainToGroup(group._id)}
                      currentColor={currentColor}
                    />
                    <CustomTooltipComponent
                      icon={MdDelete}
                      tooltipText="Delete group"
                      onClick={() => deleteGroup(group._id)}
                      currentColor={themeColorsUsable.red}
                    />
                  </Box>
                </Box>
                <table id="groupDomainsTable">
                  <thead>
                    <tr>
                      <th>Domain Name</th>
                      <th>cPanel Account</th>
                      <th>IP Address</th>
                      <th>Number Of Email Accounts</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.domains.map((domain) => {
                      return (
                        <tr key={domain._id}>
                          <td>{domain.domainName}</td>
                          <td>{domain.cPanelAccountName}</td>
                          <td>{domain.ipAddress}</td>
                          <td>{domain.numberOfEmailAccounts}</td>
                          <td>
                            <div className="flex">
                              <CustomTooltipComponent
                                icon={MdDelete}
                                tooltipText="Remove domain"
                                onClick={() => {
                                  removeDomainFromGroup(group._id, domain._id);
                                }}
                                currentColor={themeColorsUsable.red}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            );
          })
        ) : !loadingIndicatorActive ? (
          <Box sx={{ textAlign: "center" }}>No Groups Found</Box>
        ) : null}
      </Box>
      {productionGroups.length && productionGroups.length > 10 ? (
        <div className="flex justify-between items-center my-4">
          <Select
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
          />
        </div>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomModal
        open={openCreateGroupModal}
        handleClose={() => setOpenCreateGroupModal(false)}
        elements={elementsCreateGroupModal}
        confirmFunction={handleCreateGroup}
      />
      <CustomModal
        open={openAddDomainsToGroupModal}
        handleClose={() => {
          setOpenAddDomainsToGroupModal(false);
          setSelectedGroupDomainsIds([]);
          setEditingGroup(null);
        }}
        elements={elementsAddDomainsToGroupModal}
        confirmFunction={handleAddDomainsToGroup}
      />
    </div>
  );
};

export default ProductionGroups;
