import React, { useState, useEffect } from "react";
import {
  CustomLoadingIndicator,
  CustomTooltipComponent,
  Header,
} from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { MdEdit, MdAdd, MdDelete, MdChevronRight } from "react-icons/md";
import { themeColorsUsable } from "../../data/buildData";
import { formatCustomDate } from "../../utils/helper";

const Campaigns = () => {
  const { loggedIn, loadingIndicatorActive, setLoadingIndicatorActive } =
    useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [allCampaigns, setAllCampaigns] = useState([]);
  const [draftCampaigns, setDraftCampaigns] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getAllCampaigns = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.get(
        "/api/v1/production/ga/campaigns"
      );

      setLoadingIndicatorActive(false);
      if (response.data.success) {
        setAllCampaigns(response.data.data);
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoadingIndicatorActive(false);
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  const deleteDraftCampaignClick = async (campaignIdToRemove) => {
    setLoadingIndicatorActive(true);
    let draft_campaigns = JSON.parse(localStorage.draft_campaigns);
    const updated_draft_campaigns = draft_campaigns.filter(
      (campaign) => campaign.id !== campaignIdToRemove
    );
    if (updated_draft_campaigns.length) {
      localStorage.draft_campaigns = JSON.stringify(updated_draft_campaigns);
    } else {
      localStorage.removeItem("draft_campaigns");
    }
    setDraftCampaigns(updated_draft_campaigns);
    setTimeout(() => {
      setLoadingIndicatorActive(false);
    }, 1000);
  };

  const handleDeleteCampaign = async (campaign_id) => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        "/api/v1/production/ga/delete_campaign",
        { campaign_id }
      );

      setLoadingIndicatorActive(false);
      if (response.data.success) {
        getAllCampaigns();
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoadingIndicatorActive(false);
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getAllCampaigns();
      let draft_campaigns = localStorage.draft_campaigns
        ? JSON.parse(localStorage.draft_campaigns)
        : [];
      setDraftCampaigns(draft_campaigns);
    } else {
      navigate("/login");
    }
  }, [loggedIn]);

  return (
    <Box className="m-2 p-2 md:p-10 h-full bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Production" title="Campaigns" />
      <Box className="flex justify-end my-5">
        <CustomTooltipComponent
          icon={MdAdd}
          tooltipText="Create a campaign"
          onClick={() => navigate("/create-campaign")}
          currentColor={themeColorsUsable.green}
        />
      </Box>
      <Box>
        {allCampaigns.length ? (
          <table id="groupDomainsTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Campaign Name</th>
                <th>Mailing List Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allCampaigns.map((campaign) => {
                return (
                  <tr key={campaign.id}>
                    <td>{campaign.id}</td>
                    <td>{campaign.name}</td>
                    <td>{campaign.mailing_list_name}</td>
                    <td>
                      <div>{campaign.dispatch?.state || "-"}</div>
                      {campaign.dispatch?.state === "scheduled" ? (
                        <div>
                          Starts At:{" "}
                          <b>
                            {formatCustomDate(campaign.dispatch?.begins_at)}
                          </b>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <CustomTooltipComponent
                          icon={MdDelete}
                          tooltipText="Delete"
                          onClick={() => handleDeleteCampaign(campaign.id)}
                          currentColor={themeColorsUsable.red}
                        />
                      </div>
                      {/* {campaign.dispatch?.state === "idle" ||
                      campaign.dispatch?.state === "scheduled" ? (
                        <div className="flex justify-center">
                          <CustomTooltipComponent
                            icon={MdEdit}
                            tooltipText="Edit"
                            onClick={() => {
                              navigate("/create-campaign", {
                                state: { editingCampaignId: campaign.id },
                              });
                            }}
                            currentColor={themeColorsUsable.red}
                          />
                        </div>
                      ) : null} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : !loadingIndicatorActive ? (
          <Box sx={{ textAlign: "center" }}>No Campaigns Found</Box>
        ) : null}
      </Box>
      <Box sx={{ my: 5 }}>
        <Typography variant="h4">Saved For Later</Typography>
        <Box sx={{ my: 3 }}>
          {draftCampaigns.length ? (
            <table id="groupDomainsTable">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Mailing List Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {draftCampaigns.map((campaign, index) => {
                  return (
                    <tr key={campaign.id}>
                      <td>{campaign.name || "-"}</td>
                      <td>{campaign.mailing_list?.label || "-"}</td>
                      <td>
                        <div>draft</div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <CustomTooltipComponent
                            icon={MdChevronRight}
                            tooltipText="Continue Setup"
                            onClick={() =>
                              navigate("/create-campaign", {
                                state: { savedCampaign: campaign },
                              })
                            }
                            currentColor={themeColorsUsable.red}
                          />
                          <CustomTooltipComponent
                            icon={MdDelete}
                            tooltipText="Delete"
                            onClick={() => {
                              deleteDraftCampaignClick(campaign.id);
                            }}
                            currentColor={themeColorsUsable.red}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : !loadingIndicatorActive ? (
            <Box sx={{ textAlign: "center" }}>No Drafts Found</Box>
          ) : null}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />
    </Box>
  );
};

export default Campaigns;
